import React from 'react';
import { Header, Card, Message } from 'semantic-ui-react';
import ProspectCard from './ProspectCard';

const ProspectPicker = ({ scheduledPhoneCall, outcomes, handleProspectSelection, displayErrors }) => {

  return (
    <>
      <Header as='h3'>Pick from recommended prospects:</Header>
      {displayErrors && displayErrors.cannotFindProspect && (
        <Message negative>
          <Message.Header>Cannot save changes</Message.Header>
          <p>
            Please either find a prospect or confirm that the prospect cannot be found in our database or on LinkedIn.
          </p>
        </Message >
      )}
      <Card.Group itemsPerRow={1}>
        {scheduledPhoneCall.prospects.map((prospect, i) =>
          <ProspectCard
            prospect={prospect}
            key={i}
            handleProspectSelection={handleProspectSelection}
            phoneCallOutcomes={outcomes}
            scheduledPhoneCall={scheduledPhoneCall}
          />
        )}
        {scheduledPhoneCall.prospects.length === 0 && (
          <Header as='h4' style={{ marginTop: 10 }}>We can't match any prospect with this phone number.</Header>
        )}
      </Card.Group>
    </>
  )
}

export default ProspectPicker;
