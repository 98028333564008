import React, { useState } from 'react'
import { Icon, Segment, Button,  } from 'semantic-ui-react'
import ProspectsView from '../../../shared/ProspectView.js';
import SearchProspects from './SearchProspects.js';
import CreateProspect from './CreateProspect.js';
import NoProspect from './NoProspect.js';
import ProspectPicker from './ProspectPicker.js';
import axios from 'axios';
import _ from 'underscore';

const ProspectView = (props) => {
  const [loading, setLoading] = useState(false)
  const {
    scheduledPhoneCall, setScheduledPhoneCall, outcomes, blockProspectChange,
    setPhonesAndSelectedDataProviderInformation, displayErrors,
  } = props

  const handleProspectSelection = (prospect) => {
    setLoading(true)
    axios.post(`/cold_calling/incoming/${scheduledPhoneCall.id}/associate_prospect`, { prospectId: prospect.id }).then(({ data }) => {
      setScheduledPhoneCall((prevScheduledPhoneCall) => {
        let mergedPhoneCalls = data.phoneCalls.map(call => {
          let prevCall = prevScheduledPhoneCall.phoneCalls.find(c => c.id == call.id)
          return prevCall || call
        })

        // We don't want to change the fields that could be changed by user and not yet saved
        // so we merge the data into the new scheduledPhoneCall
        let preservedFields = ['phoneCallOutcomeId', 'notes', 'scheduledFor', 'blacklistProspect', 'callbackDistributionStrategy', 'callbackTime']
        let preservedData = _.pick(prevScheduledPhoneCall, preservedFields)

        let newScheduledPhoneCall = {
          ...data,
          ...preservedData,
          changeProspect: false,
          cannotFindProspect: false,
          phoneCalls: mergedPhoneCalls,
        }
        return newScheduledPhoneCall
      })

      setPhonesAndSelectedDataProviderInformation(data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      console.error(error)
    })
  }

  return (
    <>
      { scheduledPhoneCall?.phoneCallCampaignGroupAction && !scheduledPhoneCall.changeProspect ? (
        <>
          {!blockProspectChange && (
            <Button.Group>
              <Button labelPosition="left" icon onClick={() => setScheduledPhoneCall((prevScp) => ({ ...prevScp, changeProspect: true }))}>
                <Icon name="exchange" />
                Change Prospect
              </Button>
              <Button.Or />
              <Button labelPosition="right" icon onClick={() => handleProspectSelection({ id: null })}>
                <Icon name="close" />
                Unassign Prospect
              </Button>
            </Button.Group>
          )}
          <ProspectsView
            id={scheduledPhoneCall?.prospect?.id}
            crmStatuses={props.crmStatuses}
            users={props.users}
            currentUserId={props.currentUserId}
            campaigns={props.campaigns}
            tags={props.tags}
            onProspectChange={() => { }}
            renderColdCallingCardView={true}
          />
        </>
      ) : (
        <>
          {scheduledPhoneCall.prospect && (
            <Button icon labelPosition="left" onClick={() => setScheduledPhoneCall((prevScp) => ({ ...prevScp, changeProspect: false }))}>
              <Icon name="close" />
              Cancel Prospect Change
            </Button>
          )}
          <h2>Assign Prospect</h2>
            <Segment style={{ marginTop: scheduledPhoneCall.prospect ? 15 : 20, borderColor: displayErrors?.cannotFindProspect && '#9F3A38' }} loading={loading}>

              <ProspectPicker
                scheduledPhoneCall={scheduledPhoneCall}
                setScheduledPhoneCall={setScheduledPhoneCall}
                outcomes={outcomes}
                handleProspectSelection={handleProspectSelection}
                displayErrors={displayErrors}
              />
              <SearchProspects
                scheduledPhoneCall={scheduledPhoneCall}
                suggestedProspects={scheduledPhoneCall.prospects}
                outcomes={outcomes}
                handleProspectSelection={handleProspectSelection}
              />
              <CreateProspect
                suggestedProspects={scheduledPhoneCall.prospects}
                outcomes={outcomes}
                handleProspectSelection={handleProspectSelection}
              />
              <NoProspect
                suggestedProspects={scheduledPhoneCall.prospects}
                outcomes={outcomes}
                handleProspectSelection={handleProspectSelection}
                displayErrors={displayErrors}
                scheduledPhoneCall={scheduledPhoneCall}
                setScheduledPhoneCall={setScheduledPhoneCall}
              />
          </Segment>
        </>
      )}
    </>
  )
}

export default ProspectView
