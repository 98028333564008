import React from "react";
import { Grid, Form, Divider } from "semantic-ui-react";
import { present } from "../../../shared/utils.js";

const NoProspect = ({ displayErrors, scheduledPhoneCall, setScheduledPhoneCall }) => {
  return (
    <>
      <Divider section horizontal>
        <div> <p style={{ textTransform: 'none', fontSize: 14 }}>No results?</p> </div>
      </Divider>
      <Form id="no-prospect" style={{ marginBottom: 20 }} error={displayErrors} >
        <Grid columns='equal'>
          <Form.Checkbox
            label="Prospect cannot be found in our database or on LinkedIn"
            error={displayErrors && present(displayErrors.cannotFindProspect) &&
              'Please mark this checkbox if you cannot find any prospect for this call or select a prospect.'
            }
            checked={scheduledPhoneCall.cannotFindProspect || false}
            onChange={(_e, element) => {
              if (element.checked && !window.confirm("Are you sure you were not able to find this prospect in our database, or on LinkedIn?\n\nSelecting a prospect ensures this call is assigned to the call history of the correct prospect and will allow this call to show up in statistic dashboards.")) {
                return;
              }

              setScheduledPhoneCall((prevScheduledPhoneCall) => (
                { ...prevScheduledPhoneCall, cannotFindProspect: element.checked }
              ))
            }}
          />
        </Grid>
      </Form>
    </>
  )
}

export default NoProspect;
