import React, { useState, useEffect } from 'react';
import { Button, Table, Popup, Icon } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";
import TwilioCall from '../../shared/Twilio/TwilioCall';
import ProspectCard from './CallInterface/ProspectCard';
import axios from 'axios';
import { present } from '../../shared/utils';

const IncomingCall = ({ device, call, history, phoneCallOutcomes, data, handlers, pageChangeBlocker }) => {
  const customParameters = call.customParameters;
  const parameters = call.parameters;

  const { callData } = data;
  const { setCallData } = handlers;

  const [prospects, setProspects] = useState([]);
  const [company, setCompany] = useState(null);
  const [muted, setMuted] = useState(false);

  const callInProgress = present(callData) && !callData.ended

  const handleStartCall = () => {
    if (history.location.pathname.match(/\/cold_calling\/\d+\/\d+/)) {
      localStorage.setItem('coldCallingLastPage', history.location.pathname);
    }
    history.push(`/cold_calling/incoming/${customParameters.get('scheduledPhoneCallId')}?clientWaiting=true`);
  }

  useEffect(() => {
    if (!customParameters) { return; }

    if (customParameters.get('prospectIds')) {
      let data = {
        prospectIds: customParameters.get('prospectIds').split(','),
        scheduledPhoneCallId: customParameters.get('scheduledPhoneCallId'),
      }
      axios.post('/cold_calling/incoming_prospects_data', data).then(({ data }) => {
        setProspects(data.prospects);
        setCompany(data.company);
      })
    }
  }, []);

  if (!device) { return null; }
  if (location.href.includes(`incoming/${customParameters.get('scheduledPhoneCallId')}`)) { return null; }
  if (callInProgress && callData.call != call) { return null; }

  let disablePickup = pageChangeBlocker.current && customParameters.get('phoneCallId') != pageChangeBlocker.current[1];

  const lockAndMarkAsPickedUpOnCallAccept = (phoneCallId = null) => {
    axios.get(`/cold_calling/lock/${customParameters.get('scheduledPhoneCallId') }?phoneCallId=${phoneCallId}`)
      .then(() => {
        axios.get(`/cold_calling/mark_as_picked_up/${customParameters.get('scheduledPhoneCallId')}.json?phoneCallId=${phoneCallId}`)
          .catch(error => {
            console.error('Failed to mark scheduled phone call as picked up:', error);
          })
      })
      .catch(error => {
        console.error('Failed to lock scheduled phone call:', error);
      })
  }


  const mute = (call) => {
    if (muted) {
      call._soundcache.get('incoming').play()
      setMuted(false)
    } else {
      call._soundcache.get('incoming').stop()
      setMuted(true)
    }
  }

  return (
    <Table.Row verticalAlign="middle" style={{ minHeight: 200, marginTop: 20, marginBottom: 20 }}>
      <Table.Cell style={{ marginTop: 0 }}>
        <TwilioCall
          device={device}
          phoneCallOutcomes={phoneCallOutcomes}
          forCall={call}
          selectedDataProviderInformation={{}}
          callData={callData}
          setCallData={setCallData}
          sessionScope='incoming'
          onCallEnd={handleStartCall}
          disablePickup={disablePickup}
          onCallAccept={lockAndMarkAsPickedUpOnCallAccept}
        >
          <Button onClick={() => mute(call)} icon active={muted}>
            <Icon name="bell slash" />
          </Button>
          {disablePickup && (
            <i>Please save your current progress to start this call.</i>
          )}
        </TwilioCall>
      </Table.Cell>
      <Table.Cell><b>{parameters.From}</b></Table.Cell>
      <Table.Cell>{company?.name}</Table.Cell>
      <Table.Cell>
        {prospects.slice(0, 3).map((prospect, i) =>
          <Popup
            content={<ProspectCard
              prospect={prospect}
              key={i}
              phoneCallOutcomes={phoneCallOutcomes}
              disablePickup={disablePickup}
            />}
            position="bottom left"
            trigger={<p>{prospect.name}</p>}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <Button
          color="blue"
          onClick={() => handleStartCall()}
          disabled={disablePickup}
        >
          See Details
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default withRouter(IncomingCall);
